/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  $( document ).ready(function() {
    $( window ).resize();
    
    //Slider de designaciones
    jQuery('#lista-slider-designaciones .slider-item').on('click', function(){
      jQuery('.slider-item').removeClass('is-active');
      jQuery(this).addClass('is-active');
    	var c = jQuery(this).find('.contenido').html();
    	var t = jQuery(this).find('.titulo').text();
    	jQuery('#designaciones-current-title h3').text(t);
    	jQuery('#designaciones-selected-content').html(c);
    });
    
    setTimeout(function(){ jQuery(window).resize(); }, 4000);
   
    
  });
  
  $( window ).resize(function() {
      doResize();
  });

})(jQuery); // Fully reference jQuery after this point.

function toggleMenu(){
  if( jQuery('header.banner').hasClass('is-active')){
    jQuery('header.banner').removeClass('is-active');
    jQuery('header.banner .hamburger').removeClass('is-active');
  }else{
    jQuery('header.banner').addClass('is-active');
    jQuery('header.banner .hamburger').addClass('is-active');
  }
}

function toggleMenuSearchBar(){
    if( jQuery('header.banner').hasClass('show-search')){
      jQuery('header.banner').removeClass('show-search');
    }else{
      jQuery('header.banner').addClass('show-search');
      jQuery( "input.search-field" ).focus();
    }
  }

function doResize(){
  var margen = parseInt(jQuery('.wrap').css('margin-left'))+1;
  jQuery('.elementor-column:nth-of-type(1).amorir .elementor-column-wrap').css('margin-left', -margen);
  jQuery('.elementor-column:nth-of-type(2).amorir .elementor-column-wrap').css('margin-right', -margen);
  jQuery('.elementor-column:nth-of-type(1).amorir .elementor-column-wrap').css('width', 'calc(100% + '+margen+'px)');
  jQuery('.elementor-column:nth-of-type(2).amorir .elementor-column-wrap').css('width', 'calc(100% + '+margen+'px)');
  jQuery('.sidebar-fb-grid').css('margin-right', -margen);
  jQuery('.sidebar-fb-grid').css('width', 'calc(100% + '+margen+'px)');
  
  /*if(jQuery('.novedades-index').length){
  	var nh = jQuery('.novedades-index').height();
  	jQuery('.wrap .sidebar-fb-grid').height(nh);
  }*/

}